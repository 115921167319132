import {Radio} from 'antd';
import "../common/common.css"
import "./Login.css";
import {useState} from "react";
import EmailLogin from "./EmailLogin";
import PhoneLogin from "./PhoneLogin";


function Login() {
    const EmailAddress = "Email Address"
    const PhoneNumber = "Phone Number"

    const [loginType, setLoginType] = useState(EmailAddress);
    const onChangeLoginType = (e) => {
        setLoginType(e.target.value);
    };

    return (
        <div className="login-container center">
            <div style={{display: "block"}}>
                <Radio.Group
                    value={loginType}
                    onChange={onChangeLoginType}
                    style={{
                        marginBottom: 24
                    }}
                >
                    <Radio.Button value={EmailAddress}>{EmailAddress}</Radio.Button>
                    <Radio.Button value={PhoneNumber}>{PhoneNumber}</Radio.Button>
                </Radio.Group>
                {
                    loginType === EmailAddress ? <EmailLogin/> : <PhoneLogin/>
                }
            </div>

        </div>
    )
}

export default Login;