import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./home";
import SecretKeeper from "./secret-keeper";
import {useEffect} from "react";
import {Upload} from "./upload";
import {Download} from "./download";
import {Login} from "./login";

function App() {
    useEffect(() => {
        document.title = 'OlaPie';
        const lang = navigator.language;
        console.log(lang);
        localStorage.setItem("i18nextLng", lang);
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<Home/>}/>
                    <Route path="secretkeeper/*" element={<SecretKeeper/>}/>
                    <Route path="upload" element={<Upload/>}/>
                    <Route path="download" element={<Download/>}/>
                    <Route path="login" element={<Login/>} />
                    <Route path="*" element={<Home/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
