import {Button, Col, Form, Input, Row, Select,} from 'antd';

function PhoneLogin() {
    const { Option } = Select;

    const formItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="1">+1</Option>
                <Option value="86">+86</Option>
            </Select>
        </Form.Item>
    );

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    return (
        <Form
            {...formItemLayout}
            form={form}
            name="login"
            onFinish={onFinish}
            scrollToFirstError
        >
            <Form.Item
                name="login"
                rules={[
                    {
                        required: true,
                        message: 'Please input your phone number!',
                    },
                ]}
            >
                <Input
                    addonBefore={prefixSelector}
                    style={{
                        width: '100%',
                    }}
                    placeholder = "Phone number"
                />
            </Form.Item>

            <Form.Item>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            name="verification_code"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the verification code you got!',
                                },
                            ]}
                        >
                            <Input placeholder="Verification code" />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Button style={{width:"100%"}}>Get</Button>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PhoneLogin;