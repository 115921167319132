import {useEffect} from "react";
import "./Home.css";
import {useTranslation} from "react-i18next";

function Home() {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        document.title = i18n.t('home-title');
    }, [i18n]);

    return (
        <div className="Home">
            <header className="Home-header">
                {t('home-welcome')}
            </header>
        </div>
    );
}

export default Home;
