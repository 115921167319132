import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        document.title = i18n.t('secret-keeper-privacy-policy-title');
    }, [i18n]);

    return (
        <div className="PrivacyPolicy">
            <h1 className="PrivacyPolicy-h1">{t('secret-keeper-privacy-policy-title')}</h1>
            <ul>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-1')}</li>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-2')}</li>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-3')}</li>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-4')}</li>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-5')}</li>
                <li className="PrivacyPolicy-li">{t('secret-keeper-privacy-policy-6')}</li>
            </ul>
        </div>
    );
}

export default PrivacyPolicy;