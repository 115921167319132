import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "home-title": "OlaPie",
            "home-welcome": "Welcome to OlaPie",
            "secret-keeper-help-title": "SecretKeeper - Help",
            "secret-keeper-help-q1": "Only master password, no username",
            "secret-keeper-help-q1-a1": "SecretKeeper does not have any server, so username is unnecessary.",
            "secret-keeper-help-q1-a2": "Password represents account. One master password means one account.",
            "secret-keeper-help-q2": "Multi accounts are supported",
            "secret-keeper-help-q2-a": "Two accounts are recommended. One daily account and one fake account in case your device is checked by someone.",
            "secret-keeper-help-q3": "Data encryption",
            "secret-keeper-help-q3-a": "All data, including photos, videos, audios, notes, passwords, browsing histories and thumbnail photos, are encrypted with a key derived from your master password.",
            "secret-keeper-help-q4": "Data storage location",
            "secret-keeper-help-q4-a": "Data is only stored on your phone.",
            "secret-keeper-help-q5": "Data loss prevention",
            "secret-keeper-help-q5-a": "You can use iCloud to back up the data. All data is encrypted.",
            "secret-keeper-help-q6": "Data leakage prevention",
            "secret-keeper-help-q6-a": "All data is fully encrypted. Decryption is performed in memory and does not generate any temporary disk files. Without your master password, no one can access your data.",
            "secret-keeper-help-q7": "Maximum size of a single file",
            "secret-keeper-help-q7-a": "There is no explicit limitation. It is up to the RAM of your phone as decryption is performed in memory to get high-level security. To get a better experience, it is recommended not storing a file larger than 500MB.",
            "secret-keeper-help-q8": "Download to other devices",
            "secret-keeper-help-q8-a": "First, connect your phone and other devices to the same WiFi, visit download page of your file, download the file to other devices via the file url and one-time master password.",
            "secret-keeper-help-q9": "Data Recovery without master password",
            "secret-keeper-help-q9-a1": "There is no way to recover data if you forget the master password.",
            "secret-keeper-help-q9-a2": "Do not forget the master password!!!",
            "secret-keeper-help-contact": "If you have any question, feel free to send email to yxzbox###gmail.com",


            "secret-keeper-privacy-policy-title": "SecretKeeper - Privacy Policy",
            "secret-keeper-privacy-policy-1": "Master password is equivalent to account.",
            "secret-keeper-privacy-policy-2": "Data is fully encrypted with master password.",
            "secret-keeper-privacy-policy-3": "Data is only stored on this phone.",
            "secret-keeper-privacy-policy-4": "Network permission is not required by default as this APP won't upload any data.",
            "secret-keeper-privacy-policy-5": "There's no way to recover data if master password is lost.",
            "secret-keeper-privacy-policy-6": "User is responsible for memorize the master password and don't let others know it.",

            "upload": "Upload",
            "upload-success": "Uploaded successfully",
            "upload-failure": "Failed uploading",
        }
    },
    "zh-Hant": {
        translation: {
            "home-title": "OlaPie",
            "home-welcome": "歡迎來到OlaPie",
            "secret-keeper-help-title": "密安-用戶手冊",
            "secret-keeper-help-q1": "為什麼註冊只需要輸入密碼？",
            "secret-keeper-help-q1-a1": "本軟件沒有服務器，不需要用戶名。",
            "secret-keeper-help-q1-a2": "密碼等同於賬號，一個密碼對應於一個賬號。",
            "secret-keeper-help-q2": "支持多賬號嗎？",
            "secret-keeper-help-q2-a": "是的。建議設置兩個賬號，一個賬號常使用，另外一個賬號可以作為馬甲。即使對象戀人查手機也不怕了。",
            "secret-keeper-help-q3": "數據如何加密嗎？",
            "secret-keeper-help-q3-a": "加密密鑰由註冊密碼生成，照片、視頻、音頻、備忘錄、密碼、網絡瀏覽記錄等所有內容，包含縮略圖均是加密存儲。",
            "secret-keeper-help-q4": "數據保存在哪裡嗎？",
            "secret-keeper-help-q4-a": "數據只保存在手機上，不會上傳到任何地方。",
            "secret-keeper-help-q5": "如何防止數據丟失？",
            "secret-keeper-help-q5-a": "數據可以通過iCloud進行備份，備份的都是加密數據。",
            "secret-keeper-help-q6": "如何防止數據洩漏？",
            "secret-keeper-help-q6-a": "所有數據全程加密，只有用戶才能查看，任何人都無法知曉你的密碼。",
            "secret-keeper-help-q7": "文件大小有限制嗎？",
            "secret-keeper-help-q7-a": "建議文件大小不要超過500MB。",
            "secret-keeper-help-q8": "可以下載到電腦嗎？",
            "secret-keeper-help-q8-a": "首先將手機和電腦連接同一個WiFi，然後在文件的下載頁面，可以通過局域網地址及一次性密碼進行訪問。",
            "secret-keeper-help-q9": "忘記了主密碼怎麼辦？",
            "secret-keeper-help-q9-a1": "忘記了主密碼，則無法恢復數據。",
            "secret-keeper-help-q9-a2": "請確保記住主密碼!!!",
            "secret-keeper-help-contact": "如有任何其他問題，歡迎發送郵件至：yxzbox###gmail.com",

            "secret-keeper-privacy-policy-title": "密安 - 隱私條例",
            "secret-keeper-privacy-policy-1": "主密碼等同於賬號。",
            "secret-keeper-privacy-policy-2": "用戶數據由主密碼加密存儲。",
            "secret-keeper-privacy-policy-3": "用戶數據僅在手機上存儲。",
            "secret-keeper-privacy-policy-4": "本軟件不會上傳任何用戶數據，因此可以在沒有手機網絡權限的情況下使用多數功能。",
            "secret-keeper-privacy-policy-5": "如果用戶主密碼丟失，沒有辦法恢復數據。",
            "secret-keeper-privacy-policy-6": "用戶有責任記住主密碼並且不要洩漏給他人。",


            "upload-title": "文件上传",
            "upload": "上传",
            "upload-success": "文件上传成功",
            "upload-failure": "文件上传失败",
        },
    },
    "zh-Hans": {
        translation: {
            "home-title": "OlaPie",
            "home-welcome": "欢迎来到OlaPie",
            "secret-keeper-help-title": "密安-用户手册",
            "secret-keeper-help-q1": "为什么注册只需要输入密码？",
            "secret-keeper-help-q1-a1": "本软件没有服务器，不需要用户名。",
            "secret-keeper-help-q1-a2": "密码等同于账号，一个密码对应于一个账号。",
            "secret-keeper-help-q2": "支持多账号吗？",
            "secret-keeper-help-q2-a": "是的。建议设置两个账号，一个账号常使用，另外一个账号可以作为马甲。即使对象恋人查手机也不怕了。",
            "secret-keeper-help-q3": "数据如何加密吗？",
            "secret-keeper-help-q3-a": "加密密钥由注册密码生成，照片、视频、音频、备忘录、密码、网络浏览记录等所有内容，包含缩略图均是加密存储。",
            "secret-keeper-help-q4": "数据保存在哪里吗？",
            "secret-keeper-help-q4-a": "数据只保存在手机上，不会上传到任何地方。",
            "secret-keeper-help-q5": "如何防止数据丢失？",
            "secret-keeper-help-q5-a": "数据可以通过iCloud进行备份，备份的都是加密数据。",
            "secret-keeper-help-q6": "如何防止数据泄漏？",
            "secret-keeper-help-q6-a": "所有数据全程加密，只有用户才能查看，任何人都无法知晓你的密码。",
            "secret-keeper-help-q7": "文件大小有限制吗？",
            "secret-keeper-help-q7-a": "建议文件大小不要超过500MB。",
            "secret-keeper-help-q8": "可以下载到电脑吗？",
            "secret-keeper-help-q8-a": "首先将手机和电脑连接同一个WiFi，然后在文件的下载页面，可以通过局域网地址及一次性密码进行访问。",
            "secret-keeper-help-q9": "忘记了主密码怎么办？",
            "secret-keeper-help-q9-a1": "忘记了主密码，则无法恢复数据。",
            "secret-keeper-help-q9-a2": "请确保记住主密码!!!",
            "secret-keeper-help-contact": "如有任何其他问题，欢迎发送邮件至：yxzbox###gmail.com",

            "secret-keeper-privacy-policy-title": "密安 - 隱私條例",
            "secret-keeper-privacy-policy-1": "主密碼等同於賬號。",
            "secret-keeper-privacy-policy-2": "用戶數據由主密碼加密存儲。",
            "secret-keeper-privacy-policy-3": "用戶數據僅在手機上存儲。",
            "secret-keeper-privacy-policy-4": "本軟件不會上傳任何用戶數據，因此可以在沒有手機網絡權限的情況下使用多數功能。",
            "secret-keeper-privacy-policy-5": "如果用戶主密碼丟失，沒有辦法恢復數據。",
            "secret-keeper-privacy-policy-6": "用戶有責任記住主密碼並且不要洩漏給他人。",

            "upload-title": "文件上傳",
            "upload": "上傳",
            "upload-success": "文件上傳成功",
            "upload-failure": "文件上傳失敗",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: {
            'zh': ['zh-Hans'],
            'zh-TW': ['zh-Hant'],
            'zh-HK': ['zh-Hant'],
            'zh-CN': ['zh-Hans'],
            'zh-SG': ['zh-Hans'],
            'default': ['en']
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

export default i18n;