import React, {useEffect, useState} from 'react';
import "./Upload.css";
import {UploadOutlined} from '@ant-design/icons';
import {Button, message, Upload as AntUpload} from 'antd';
import {useTranslation} from "react-i18next";
import {ProdServer} from "../common/APIUtils";


function Upload() {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        document.title = i18n.t('home-title');
    }, [i18n]);

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('files[]', file);
        });
        setUploading(true);
        // You can use any AJAX library you like
        fetch(ProdServer+'/api/v1/upload', {
            method: 'POST',
            body: formData,
        }).then((resp) => {
            const obj = resp.json()
            if (resp.status !== 200) {
                throw new Error(JSON.stringify(obj))
            }
            setFileList([]);
            message.success(t('upload-success'));
        }).catch((err) => {
            message.error(t('upload-failure') + err.message);
        }).finally(() => {
            setUploading(false);
        });
    };

    const antUploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <div className="upload-container">
            <AntUpload {...antUploadProps}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </AntUpload>
            <Button id="btn-upload"
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{
                        marginTop: 16,
                    }}
            >
                {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
        </div>
    )
}

export default Upload;