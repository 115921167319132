import {Route, Routes} from "react-router-dom";
import Help from "./Help";
import PrivacyPolicy from "./PrivacyPolicy";

function SecretKeeper() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<Help/>}/>
                <Route path="help.html" element={<Help/>}/>
                <Route path="help" element={<Help/>}/>
                <Route path="privacy-policy.html" element={<PrivacyPolicy />}/>
                <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    );
}

function NotFound() {
    return (
        <div>
            Page not found
        </div>
    )
}

export default SecretKeeper;
