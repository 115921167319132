import {Button, Col, Form, Input, message, Row,} from 'antd';
import {useState} from "react";
import {ProdServer} from "../common/APIUtils";
import axios from "axios";

function EmailLogin() {
    const [emailAddress, setEmailAddress] = useState("");
    const [validEmailAddress, setValidEmailAddress] = useState(false);
    const [sendingCode, setSendingCode] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 24,
            },
        },
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.auto_create = true
        setSubmitting(true)
        const endpoint = ProdServer + '/api/v1/sign-in'
        axios.post(endpoint, values).then(resp => {
            console.log(resp);
            message.success("Succeeded");
        }).catch((err) => {
            if (err.response) {
                // When response status code is out of 2xx range
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                message.error(err.response.data ? err.response.data : err.response.status)
            } else if (err.request) {
                // When no response was recieved after request was made
                console.log(err.request);
            } else {
                // Error
                console.log(err.message);
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onValuesChange = (values) => {
        if (values.hasOwnProperty("login")) {
            setEmailAddress(values.login.trim())
        }
    }

    const onFieldsChange = (fields) => {
        fields.forEach((field) => {
            if (field.name.includes("login")) {
                setValidEmailAddress(!field.errors.length)
            }
        })
    }

    const onSendCode = () => {
        setSendingCode(true)
        const endpoint = ProdServer + '/api/v1/verification/codes'
        const input = {
            "login": emailAddress,
            "type": 4,
        }
        axios.post(endpoint, input).then(resp => {
            console.log(resp);
            message.success("Verification code has been sent successfully");
        }).catch((err) => {
            message.error(err.message);
        }).finally(() => {
            setSendingCode(false);
        });
    }

    return (
        <Form
            {...formItemLayout}
            form={form}
            name="login"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
            scrollToFirstError
        >
            <Form.Item
                name="login"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                ]}
            >
                <Input placeholder="Email address" value={emailAddress}/>
            </Form.Item>

            <Form.Item>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            name="verification_code"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the verification code you got!',
                                },
                            ]}
                        >
                            <Input placeholder="Verification code"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Button style={{width: "100%"}}
                                disabled={!validEmailAddress}
                                loading={sendingCode}
                                onClick={onSendCode}>{sendingCode ? "" : "Get"}</Button>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" style={{width: "100%"}} loading={submitting}>
                    Login
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EmailLogin;