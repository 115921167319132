import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {message} from "antd";
import "./Download.css";
import {ProdServer} from "../common/APIUtils";

function Download() {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        document.title = i18n.t('home-title');
        fetch(ProdServer+'/api/v1/download/files', {
            method: 'GET',
        }).then(
            resp => resp.json()
        ).then((result) => {
            if (!result) {
                setFileList([]);
            }

            if (Array.isArray(result)) {
                setFileList(result);
                return
            }

            if (result.code !== 200) {
                console.log(result.code, result.message);
                throw new Error(result.message);
            }
            setFileList([]);
        }).catch((err) => {
            message.error(err.message);
        }).finally(() => {
            setLoading(false);
        });
    }, [i18n]);

    return (
        <div className="download-container">
            <h1 className="download-title">Downloads</h1>
            {
                loading ? (
                    <p>loading</p>
                ) : (
                    <ul>
                        {fileList.map((file, index) => (
                            <li>
                                <span className="download-filename">{file.filename}</span>
                                <span className="download-file-size">{file['size_text']}</span>
                                <a className="download-file-link" href={ProdServer+"/api/v1/download?id=" + file.id}>Download</a>
                            </li>
                        ))
                        }
                    </ul>
                )
            }
        </div>
    )
}

export default Download;